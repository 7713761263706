<template>
  <ui-component-modal
    :modalTitle="'Edit price settings'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :onClickCancel="onClickCancel"
    :onClickSave="saveYieldSettings"
    :hideSaveButton="isSavingError || isSavingSuccess"
    :buttonLabelCancel="isSavingError || isSavingSuccess ? 'Close' : 'Cancel'"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth">
        <tbody>
          <tr class="has-text-vertical-middle">
            <td>
              {{ $t(`Components.Options.ModalEditOptionPrices.Label_VatRate`) }}
            </td>
            <td>
              <div class="select">
                <select v-model="mYieldSettings.TaxPercentage">
                  <option
                    v-for="taxPercentage in taxPercentages"
                    :key="taxPercentage"
                    :value="taxPercentage"
                    :selected="taxPercentage === mYieldSettings.TaxPercentage"
                  >
                    {{ taxPercentage }}%
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>Calculation type</td>
            <td>
              <div
                v-for="priceCalculationType in priceCalculationTypes"
                :key="priceCalculationType.id"
              >
                <label>
                  <input
                    type="radio"
                    name="priceCalculationType"
                    @change="priceCalculationTypeClicked"
                    v-model="mYieldSettings.CalculationType"
                    :value="priceCalculationType.type"
                    :checked="
                      priceCalculationType.type ===
                      mYieldSettings.CalculationType
                    "
                  />
                  <span>{{ priceCalculationType.name }}</span>
                </label>
              </div>
            </td>
          </tr>

          <tr v-if="mYieldSettings.CalculationType !== 'PerHourPerSeat'">
            <td>Nr of hours per day part</td>
            <td>
              <select
                v-model="mYieldSettings.HoursPerDayPart"
                @change="setPriceParts"
              >
                <option
                  v-for="dayPart in dayParts"
                  :key="dayPart"
                  :value="dayPart"
                  :selected="dayPart === mYieldSettings.HoursPerDayPart"
                >
                  {{ dayPart }}
                </option>
              </select>
            </td>
          </tr>

          <tr class="has-text-vertical-middle">
            <td>{{ $t('Prices.SpacePriceCapMin') }}</td>
            <td>
              <ul class="tabs is-toggle is-inline-flex is-small">
                <li
                  :class="{
                    'is-active': mYieldSettings.SpacePriceCapMin === 0,
                  }"
                >
                  <a @click="mYieldSettings.SpacePriceCapMin = 0">
                    <span>{{ $t(`General.State_Off`) }}</span>
                  </a>
                </li>
                <li
                  :class="{
                    'is-active': mYieldSettings.SpacePriceCapMin > 0,
                  }"
                >
                  <a @click="mYieldSettings.SpacePriceCapMin = 100">
                    <span>{{ $t(`General.State_On`) }}</span>
                  </a>
                </li>
              </ul>

              <div
                v-if="mYieldSettings.SpacePriceCapMin > 0"
                class="field has-addons is-pulled-right"
              >
                <p class="control">
                  <a class="button is-static">&euro;</a>
                </p>
                <p class="control">
                  <InputNumeric
                    v-model.number="mYieldSettings.SpacePriceCapMin"
                  />
                </p>
              </div>
            </td>
          </tr>

          <tr class="has-text-vertical-middle">
            <td>{{ $t('Prices.SpacePriceCapMax') }}</td>
            <td>
              <ul class="tabs is-toggle is-inline-flex is-small">
                <li
                  :class="{
                    'is-active': mYieldSettings.SpacePriceCapMax === 0,
                  }"
                >
                  <a @click="mYieldSettings.SpacePriceCapMax = 0">
                    <span>{{ $t(`General.State_Off`) }}</span>
                  </a>
                </li>
                <li
                  :class="{
                    'is-active': mYieldSettings.SpacePriceCapMax > 0,
                  }"
                >
                  <a @click="mYieldSettings.SpacePriceCapMax = 1000">
                    <span>{{ $t(`General.State_On`) }}</span>
                  </a>
                </li>
              </ul>

              <div
                v-if="mYieldSettings.SpacePriceCapMax > 0"
                class="field has-addons is-pulled-right"
              >
                <p class="control">
                  <a class="button is-static">&euro;</a>
                </p>
                <p class="control">
                  <InputNumeric
                    :allowNegativeValue="false"
                    v-model.number="mYieldSettings.SpacePriceCapMax"
                  />
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <td>Prices</td>
            <td>
              <table class="table is-narrow is-striped">
                <tbody>
                  <tr
                    v-for="(pricePart, index) in mYieldSettings.PriceParts"
                    :key="index"
                  >
                    <td width="125">
                      <span
                        v-if="
                          mYieldSettings.CalculationType === 'PerHourPerSeat'
                        "
                        >hour
                      </span>
                      <span>{{ pricePart.Part }}</span>
                      <span
                        v-if="
                          mYieldSettings.CalculationType !== 'PerHourPerSeat' &&
                          pricePart.Part === 1
                        "
                      >
                        day part</span
                      >
                      <span
                        v-if="
                          mYieldSettings.CalculationType !== 'PerHourPerSeat' &&
                          pricePart.Part > 1
                        "
                      >
                        day parts</span
                      >
                    </td>
                    <td width="100">
                      <input
                        type="number"
                        v-model.number="pricePart.Price"
                        class="input"
                      />
                    </td>
                    <td>
                      <a @click="removePricePart(index)">
                        <span class="icon has-text-danger">
                          <font-awesome-icon :icon="['fas', 'times']" />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <a @click="addPricePart">
                        <span class="icon has-text-success">
                          <font-awesome-icon :icon="['fas', 'plus']" />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'
import InputNumeric from '@/components/UI/Form/InputNumeric'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  components: {
    InputNumeric,
  },

  data() {
    return {
      dayParts: [1, 2, 3, 4, 5, 6, 12],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mYieldSettings: null,
      priceCalculationTypes: [],
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),

    taxPercentages() {
      let result = []

      if (this.locationState) {
        result = this.locationState.TaxPercentages.map((t) => t.TaxPercentage)
      }

      return result
    },
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )

    this.priceCalculationTypes.push({
      id: 0,
      type: 'PerHourPerSeat',
      name: 'Price per seat per hour',
    })
    this.priceCalculationTypes.push({
      id: 1,
      type: 'PerDayPartOnly',
      name: 'Price per day part',
    })
    this.priceCalculationTypes.push({
      id: 2,
      type: 'PerDayPartPerPerson',
      name: 'Price per day part per person',
    })
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    getCalculationTypeId(type) {
      let calcIndex = this.priceCalculationTypes.findIndex(
        (pc) => pc.type === type
      )
      return this.priceCalculationTypes[calcIndex].id
    },

    priceCalculationTypeClicked() {
      let self = this
      if (self.mYieldSettings.CalculationType === 'PerHourPerSeat') {
        self.mYieldSettings.HoursPerDayPart = 1
        self.setPriceParts()
      }
    },

    setPriceParts() {
      let self = this

      self.mYieldSettings.PriceParts = []
      let nrOfParts = 12 / self.mYieldSettings.HoursPerDayPart

      for (let i = 0; i < nrOfParts; i++) {
        self.mYieldSettings.PriceParts.push({
          ChannelId: self.mYieldSettings.ChannelId,
          LocationId: self.mYieldSettings.LocationId,
          MeetingtypeId: self.mYieldSettings.MeetingtypeId,
          SpaceId: 0,
          Part: i + 1,
          Price: 0,
        })
      }
    },

    addPricePart() {
      let self = this
      let existingPriceParts = self.mYieldSettings.PriceParts.length
      self.mYieldSettings.PriceParts.push({
        ChannelId: self.mYieldSettings.ChannelId,
        LocationId: self.mYieldSettings.LocationId,
        MeetingtypeId: self.mYieldSettings.MeetingtypeId,
        SpaceId: 0,
        Part: existingPriceParts + 1,
        Price: 0,
      })
    },

    removePricePart(index) {
      let self = this

      self.mYieldSettings.PriceParts.splice(index, 1)

      for (let i = 0; i < self.mYieldSettings.PriceParts.length; i++) {
        self.mYieldSettings.PriceParts[i].Part = i + 1
      }
    },

    saveYieldSettings() {
      let self = this
      self.isSaving = true

      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then((response) => {
          if (response.status === 200) {
            self.isSavingSuccess = true
            self.setYieldSettingsState(self.yieldSettingsState)

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>

<style></style>
