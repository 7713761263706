var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-component-modal',{attrs:{"modalTitle":'Edit price settings',"isSaving":_vm.isSaving,"isSavingSuccess":_vm.isSavingSuccess,"isSavingError":_vm.isSavingError,"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.saveYieldSettings,"hideSaveButton":_vm.isSavingError || _vm.isSavingSuccess,"buttonLabelCancel":_vm.isSavingError || _vm.isSavingSuccess ? 'Close' : 'Cancel',"showModal":_vm.showModal},on:{"closeModal":_vm.onClickCancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('table',{staticClass:"table is-fullwidth"},[_c('tbody',[_c('tr',{staticClass:"has-text-vertical-middle"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t(`Components.Options.ModalEditOptionPrices.Label_VatRate`))+" ")]),_c('td',[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mYieldSettings.TaxPercentage),expression:"mYieldSettings.TaxPercentage"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.mYieldSettings, "TaxPercentage", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.taxPercentages),function(taxPercentage){return _c('option',{key:taxPercentage,domProps:{"value":taxPercentage,"selected":taxPercentage === _vm.mYieldSettings.TaxPercentage}},[_vm._v(" "+_vm._s(taxPercentage)+"% ")])}),0)])])]),_c('tr',[_c('td',[_vm._v("Calculation type")]),_c('td',_vm._l((_vm.priceCalculationTypes),function(priceCalculationType){return _c('div',{key:priceCalculationType.id},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mYieldSettings.CalculationType),expression:"mYieldSettings.CalculationType"}],attrs:{"type":"radio","name":"priceCalculationType"},domProps:{"value":priceCalculationType.type,"checked":priceCalculationType.type ===
                    _vm.mYieldSettings.CalculationType,"checked":_vm._q(_vm.mYieldSettings.CalculationType,priceCalculationType.type)},on:{"change":[function($event){return _vm.$set(_vm.mYieldSettings, "CalculationType", priceCalculationType.type)},_vm.priceCalculationTypeClicked]}}),_c('span',[_vm._v(_vm._s(priceCalculationType.name))])])])}),0)]),(_vm.mYieldSettings.CalculationType !== 'PerHourPerSeat')?_c('tr',[_c('td',[_vm._v("Nr of hours per day part")]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mYieldSettings.HoursPerDayPart),expression:"mYieldSettings.HoursPerDayPart"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.mYieldSettings, "HoursPerDayPart", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.setPriceParts]}},_vm._l((_vm.dayParts),function(dayPart){return _c('option',{key:dayPart,domProps:{"value":dayPart,"selected":dayPart === _vm.mYieldSettings.HoursPerDayPart}},[_vm._v(" "+_vm._s(dayPart)+" ")])}),0)])]):_vm._e(),_c('tr',{staticClass:"has-text-vertical-middle"},[_c('td',[_vm._v(_vm._s(_vm.$t('Prices.SpacePriceCapMin')))]),_c('td',[_c('ul',{staticClass:"tabs is-toggle is-inline-flex is-small"},[_c('li',{class:{
                  'is-active': _vm.mYieldSettings.SpacePriceCapMin === 0,
                }},[_c('a',{on:{"click":function($event){_vm.mYieldSettings.SpacePriceCapMin = 0}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`General.State_Off`)))])])]),_c('li',{class:{
                  'is-active': _vm.mYieldSettings.SpacePriceCapMin > 0,
                }},[_c('a',{on:{"click":function($event){_vm.mYieldSettings.SpacePriceCapMin = 100}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`General.State_On`)))])])])]),(_vm.mYieldSettings.SpacePriceCapMin > 0)?_c('div',{staticClass:"field has-addons is-pulled-right"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"button is-static"},[_vm._v("€")])]),_c('p',{staticClass:"control"},[_c('InputNumeric',{model:{value:(_vm.mYieldSettings.SpacePriceCapMin),callback:function ($$v) {_vm.$set(_vm.mYieldSettings, "SpacePriceCapMin", _vm._n($$v))},expression:"mYieldSettings.SpacePriceCapMin"}})],1)]):_vm._e()])]),_c('tr',{staticClass:"has-text-vertical-middle"},[_c('td',[_vm._v(_vm._s(_vm.$t('Prices.SpacePriceCapMax')))]),_c('td',[_c('ul',{staticClass:"tabs is-toggle is-inline-flex is-small"},[_c('li',{class:{
                  'is-active': _vm.mYieldSettings.SpacePriceCapMax === 0,
                }},[_c('a',{on:{"click":function($event){_vm.mYieldSettings.SpacePriceCapMax = 0}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`General.State_Off`)))])])]),_c('li',{class:{
                  'is-active': _vm.mYieldSettings.SpacePriceCapMax > 0,
                }},[_c('a',{on:{"click":function($event){_vm.mYieldSettings.SpacePriceCapMax = 1000}}},[_c('span',[_vm._v(_vm._s(_vm.$t(`General.State_On`)))])])])]),(_vm.mYieldSettings.SpacePriceCapMax > 0)?_c('div',{staticClass:"field has-addons is-pulled-right"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"button is-static"},[_vm._v("€")])]),_c('p',{staticClass:"control"},[_c('InputNumeric',{attrs:{"allowNegativeValue":false},model:{value:(_vm.mYieldSettings.SpacePriceCapMax),callback:function ($$v) {_vm.$set(_vm.mYieldSettings, "SpacePriceCapMax", _vm._n($$v))},expression:"mYieldSettings.SpacePriceCapMax"}})],1)]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Prices")]),_c('td',[_c('table',{staticClass:"table is-narrow is-striped"},[_c('tbody',_vm._l((_vm.mYieldSettings.PriceParts),function(pricePart,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"125"}},[(
                        _vm.mYieldSettings.CalculationType === 'PerHourPerSeat'
                      )?_c('span',[_vm._v("hour ")]):_vm._e(),_c('span',[_vm._v(_vm._s(pricePart.Part))]),(
                        _vm.mYieldSettings.CalculationType !== 'PerHourPerSeat' &&
                        pricePart.Part === 1
                      )?_c('span',[_vm._v(" day part")]):_vm._e(),(
                        _vm.mYieldSettings.CalculationType !== 'PerHourPerSeat' &&
                        pricePart.Part > 1
                      )?_c('span',[_vm._v(" day parts")]):_vm._e()]),_c('td',{attrs:{"width":"100"}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(pricePart.Price),expression:"pricePart.Price",modifiers:{"number":true}}],staticClass:"input",attrs:{"type":"number"},domProps:{"value":(pricePart.Price)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(pricePart, "Price", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('td',[_c('a',{on:{"click":function($event){return _vm.removePricePart(index)}}},[_c('span',{staticClass:"icon has-text-danger"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times']}})],1)])])])}),0),_c('tfoot',[_c('tr',[_c('td'),_c('td'),_c('td',[_c('a',{on:{"click":_vm.addPricePart}},[_c('span',{staticClass:"icon has-text-success"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1)])])])])])])])])])]},proxy:true}])},[_c('template',{slot:"modalTitle"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }